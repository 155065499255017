import { findCity, findLatLng, findState, formatAddress } from './utils'

/**
 * Geocodes an address using the Google Places API on the Client.
 *
 * Requires the Google Maps JavaScript API to be loaded.
 */
async function geocodeAddress(address: string) {
  try {
    const geocoder = new google.maps.Geocoder()
    const results = await new Promise<google.maps.GeocoderResult[]>(
      (resolve, reject) => {
        geocoder.geocode({ address }, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            resolve(results as google.maps.GeocoderResult[])
          } else {
            reject(
              new Error(
                'Unable to validate address location, please try again.',
              ),
            )
          }
        })
      },
    )
    return results[0] || null
  } catch (error) {
    console.error(error)
    throw error
  }
}

/**
 * Geocodes a latitude and longitude using the Google Places API on the Client.
 *
 * Requires the Google Maps JavaScript API to be loaded.
 */
async function geocodeLatLng(lat: number, lng: number) {
  try {
    const geocoder = new google.maps.Geocoder()
    const latLng = new google.maps.LatLng(lat, lng)
    const results = await new Promise<google.maps.GeocoderResult[]>(
      (resolve, reject) => {
        geocoder.geocode({ location: latLng }, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            resolve(results as google.maps.GeocoderResult[])
          } else {
            reject(
              new Error(
                'Unable to validate lat/lng location, please try again.',
              ),
            )
          }
        })
      },
    )
    return results[0] || null
  } catch (error) {
    console.error(error)
    throw error
  }
}

export type GetPlaceParamsType = string | { lat: number; lng: number }

/**
 * Retrieves place details based on the provided value on the Client.
 *
 * Requires the Google Maps JavaScript API to be loaded.
 *
 * @example
 * const place1 = await getPlace('1600 Amphitheatre Parkway, Mountain View, CA')
 * console.log({ place1 })
 * const place2 = await getPlace({ lat: 37.7749, lng: -122.4194 })
 * console.log({ place2 })
 */
export async function getPlace(value: GetPlaceParamsType) {
  try {
    const result =
      typeof value === 'string'
        ? await geocodeAddress(value)
        : await geocodeLatLng(value.lat, value.lng)

    if (result) {
      const { lat, lng } = findLatLng(result)
      const address = formatAddress(result)
      const city = findCity(result)?.long_name
      const state = findState(result)?.short_name
      return { lat, lng, address, city, state }
    }
  } catch (error) {
    console.error(error)
    throw error
  }
  return null
}
