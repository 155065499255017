import { QueryClient, UseQueryOptions } from '@tanstack/react-query'
import fetcher from '../fetcher'
import {
  FetcherError,
  SkillsDocument,
  SkillsQuery,
  SkillsQueryVariables as SkillsVars,
  useSkillsQuery as useEndpoint,
} from '../types'

type SkillsOptions = UseQueryOptions<SkillsQuery, FetcherError, SkillsQuery>

/**
 * @private
 * Retrieve the stringified `Skills` query key used in the internal cache.
 */
const getKey = useEndpoint.getKey

/**
 * @private
 * Retrieve the `Skills` from the queryClient's cache.
 */
function getCache(queryClient: QueryClient, variables?: SkillsVars) {
  const key = getKey(variables)
  return queryClient.getQueryData<SkillsQuery>(key)
}

// -------------------------------------

/**
 * Convenience wrapper around react-query's `invalidateQueries` function.
 *
 * @see https://react-query.tanstack.com/guides/query-invalidation
 *
 * @example
 * invalidateSkills(queryClient)
 */
function invalidateSkills(queryClient: QueryClient, variables?: SkillsVars) {
  queryClient.invalidateQueries(variables ? getKey(variables) : ['Skills'])
}

/**
 * Prefetch and return the list of `Skills`.
 *
 * @example
 * const skills = await prefetchSkills(queryClient)
 */
async function prefetchSkills(
  queryClient: QueryClient,
  variables?: SkillsVars,
) {
  const key = getKey(variables)
  const fn = fetcher<SkillsQuery, SkillsVars>(SkillsDocument, variables)
  await queryClient.prefetchQuery(key, fn)
  return getCache(queryClient, variables)
}

/**
 * Fetch a `SkillsFragment` and derive a `SkillsComputed` data set.
 * Passing `initialData` will hydrate the cache.
 *
 * The staleTime is set to Infinity for a single fetch.
 *
 * @example
 * const { data, uri } = useSkills()
 * const { data, uri } = useSkills(_, { initialData: node })
 */

function useSkills(variables?: SkillsVars, options?: SkillsOptions) {
  const query = useEndpoint(variables, {
    staleTime: Infinity,
    ...options,
  })
  return query
}

// -------------------------------------

export { invalidateSkills, prefetchSkills }
export default useSkills
