import { companySizes } from '@vangst/services/oogst/company/useCompany'
import React from 'react'
import ControlGroup from '../../../components/forms/ControlGroup'
import Radio from '../../../components/forms/Radio'

type PropsType = React.InputHTMLAttributes<
  HTMLInputElement | HTMLLabelElement
> & {
  readonly error?: string
  readonly hideLabel?: boolean
  readonly hint?: string | null
  readonly label: string
  readonly layout?: 'col' | 'row'
  readonly name: string
  readonly reference?: React.Ref<HTMLInputElement>
  readonly restProps?: object
}

function ChooseCompanySizeRadio(props: PropsType) {
  const {
    error,
    hint,
    hideLabel = false,
    label,
    layout = 'col',
    name,
    reference,
    restProps,
  } = props
  companySizes[0] = { label: 'All Companies', value: '' }
  return (
    <ControlGroup
      disabled={props.disabled}
      error={error}
      hideLabel={hideLabel}
      hint={hint}
      label={label}
      layout={layout}
      name={name}
      type="checkbox"
    >
      {companySizes.map((size) => {
        return (
          <Radio
            key={size.value}
            id={size.label}
            name={name}
            label={size.label}
            reference={reference}
            value={size.value}
            {...restProps}
          />
        )
      })}
    </ControlGroup>
  )
}

export default React.memo(ChooseCompanySizeRadio)
