import { Popover } from '@headlessui/react'
import clsx from '@vangst/lib/clsx'
import isEmptyOrNil from '@vangst/lib/isEmptyOrNil'
import { companySizeMap } from '@vangst/services/oogst/company/useCompany'
import { employmentTypeMappings } from '@vangst/services/oogst/jobPosting/useJobPosting'
import { ElasticSearchEndpoints } from '@vangst/services/oogst/search/useElasticSearch'
import {
  AtsRecordTypeEnum,
  CompanySizeEnum,
  EmploymentTypesEnum,
  HiringStatusEnum,
} from '@vangst/services/oogst/types'
import useViewer from '@vangst/services/oogst/viewer/useViewer'
import { useRouter } from 'next/router'
import { memo, useState } from 'react'
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form'
import Busy from '../../../components/feedback/Busy'
import FilterPopover from '../../../components/feedback/FilterPopover'
import Checkbox from '../../../components/forms/Checkbox'
import ChooseBooleanCheckbox from '../../application/forms/ChooseBooleanCheckbox'
import ChooseAtsRecordType from '../../job/forms/ChooseAtsRecordType'
import ChooseCompanySizeRadio from '../../job/forms/ChooseCompanySizeRadio'
import ChooseJobDateRangeRadio from '../../job/forms/ChooseJobDateRangeRadio'
import ChooseRadiusRadio from '../../job/forms/ChooseRadiusRadio'
import ChooseEmploymentTypes from '../../member/forms/ChooseEmploymentTypes'
import ChooseHiringStatuses from '../../member/forms/ChooseHiringStatuses'
import ChooseLicenseCombo from './ChooseLicenseCombo'
import ChooseSkillsCombo from './ChooseSkillsCombo'

type SearchParamsType = {
  readonly radiusLocation?: {
    latitude: number
    longitude: number
    radius: number
  }
  readonly matchLocation?: {
    city: string
    state: string
  }
}

type PropsType = React.HTMLAttributes<HTMLDivElement> & {
  readonly change: () => void
  readonly endpoint?: ElasticSearchEndpoints
  readonly register: UseFormRegister<any>
  readonly setValue: UseFormSetValue<any>
  readonly getValues: UseFormGetValues<any>
  readonly variables?: SearchParamsType
}

function extractRadiusValue(str: string): number | null {
  const regex = /radius=(-?\d+(\.\d+)?)/
  const match = str.match(regex)
  return match && match[1] ? parseFloat(match[1]) : null
}

const RadiusFilter = memo(function SearchJobFilters(props: PropsType) {
  const { query } = useRouter()
  const { register, variables } = props
  const { ref: radiusRef, ...radiusProps } = register('radiusLocation.radius')
  const value = query?.radiusLocation
    ? extractRadiusValue(query.radiusLocation as string) + ' mi'
    : variables?.radiusLocation?.radius || null

  return (
    <FilterPopover
      disabled={
        query?.radiusLocation == null && variables?.radiusLocation == null
      }
      isFiltering={!isEmptyOrNil(query?.radiusLocation)}
      label={(value as string) ?? 'Distance'}
    >
      <ChooseRadiusRadio
        hideLabel
        label="Job Radius"
        name="radiusLocation.radius"
        reference={radiusRef}
        restProps={radiusProps}
      />
    </FilterPopover>
  )
})

// -------------------------------------

const SearchJobFilters = memo(function SearchJobFilters(props: PropsType) {
  const { query } = useRouter()
  const { endpoint, register, setValue, getValues, change } = props
  const [toggle, setToggle] = useState(true)

  const { ref: postedDateRef, ...postedDateProps } = register('betweenTimes')
  const { ref: licenseRef, ...licenseProps } = register('requiredLicenseIds')
  const { ref: employmentTypesRef, ...employmentTypesProps } =
    register('employmentTypes')
  const { ref: isRemoteRef, ...isRemoteProps } = register('isRemote')
  const { ref: atsRecordTypeRef, ...atsRecordTypeProps } =
    register('atsRecordTypes')
  const { ref: publishedStatusRef, ...publishedStatusProps } =
    register('isPublished')
  const { ref: jobClientVisibilityRef, ...jobClientVisibilityProps } =
    register('visibleClient')

  const licenseValue = getValues('requiredLicenseIds')

  const handleValueChange = (name: string, val: string[]) => {
    setValue(name, val)
    setToggle(!toggle)
    change()
  }

  return (
    <>
      <RadiusFilter {...props} />
      <FilterPopover
        isFiltering={!isEmptyOrNil(query?.betweenTimes)}
        label={
          query?.betweenTimes ? query.betweenTimes + ' days' : 'Date Posted'
        }
      >
        <ChooseJobDateRangeRadio
          label="Posted Date"
          hideLabel
          name="betweenTimes"
          reference={postedDateRef}
          restProps={postedDateProps}
        />
      </FilterPopover>

      <FilterPopover
        isFiltering={!isEmptyOrNil(query?.isRemote)}
        label={
          query?.isRemote === 'true'
            ? 'Remote'
            : query?.isRemote === 'false'
            ? 'In Person'
            : 'Remote / In Person'
        }
      >
        <ChooseBooleanCheckbox
          label="Remote / In Person"
          reference={isRemoteRef}
          restProps={isRemoteProps}
          hideLabel
          name="isRemote"
          trueLabel="Remote"
          falseLabel="In Person"
        />
      </FilterPopover>

      <FilterPopover
        isFiltering={!isEmptyOrNil(query?.employmentTypes)}
        label={
          query?.employmentTypes
            ? getEmploymentTypesLabel(query.employmentTypes as string)
            : 'Employment Types'
        }
      >
        <ChooseEmploymentTypes
          hideLabel
          label="Employment Types"
          reference={employmentTypesRef}
          restProps={employmentTypesProps}
          name="employmentTypes"
        />
      </FilterPopover>

      <FilterPopover
        isFiltering={!isEmptyOrNil(query?.requiredLicenseIds)}
        label="Required Licenses"
      >
        <ChooseLicenseCombo
          handleValueChange={handleValueChange}
          value={licenseValue}
          label="License"
          hideLabel
          reference={licenseRef}
          {...licenseProps}
        />
      </FilterPopover>

      {endpoint === 'searchJobPostingsByRecruiters' && (
        <>
          <FilterPopover
            isFiltering={!isEmptyOrNil(query?.isPublished)}
            label={
              query?.isPublished === 'true'
                ? 'Published'
                : query?.isPublished === 'false'
                ? 'Unpublished'
                : 'Published Status'
            }
          >
            <ChooseBooleanCheckbox
              label="Published Status"
              reference={publishedStatusRef}
              restProps={publishedStatusProps}
              name="isPublished"
              trueLabel="Published"
              falseLabel="Unpublished"
            />
          </FilterPopover>
          <FilterPopover
            isFiltering={!isEmptyOrNil(query?.visibleClient)}
            label={
              query?.visibleClient === 'true'
                ? 'Visible Companies'
                : query?.visibleClient === 'false'
                ? 'Hidden Companies'
                : 'Company Visibility'
            }
          >
            <ChooseBooleanCheckbox
              label="Company Visibility"
              reference={jobClientVisibilityRef}
              restProps={jobClientVisibilityProps}
              name="visibleClient"
              trueLabel="Visible Companies"
              falseLabel="Hidden Companies"
            />
          </FilterPopover>

          <FilterPopover
            isFiltering={!isEmptyOrNil(query?.atsRecordTypes)}
            label={
              query?.atsRecordTypes
                ? getAtsRecordLabel(query.atsRecordTypes as string)
                : 'Job Type'
            }
          >
            <ChooseAtsRecordType
              register={register}
              reference={atsRecordTypeRef}
              {...atsRecordTypeProps}
            />
          </FilterPopover>
        </>
      )}
    </>
  )
})

const getAtsRecordLabel = (types: string | undefined) => {
  if (!types) return 'Job Type'
  const mapped = {
    [AtsRecordTypeEnum.BoutiqueDh]: 'Direct Hire',
    [AtsRecordTypeEnum.BoutiqueGigs]: 'Gigs',
    [AtsRecordTypeEnum.PlatformFree]: 'Self Service',
  }
  return types
    .split(',')
    .map((type) => mapped[type as AtsRecordTypeEnum])
    .join(', ')
}

const getEmploymentTypesLabel = (types: string | undefined) => {
  if (!types) return 'Employment Types'
  return types
    .split(',')
    .map((type) => employmentTypeMappings[type as EmploymentTypesEnum])
    .join(', ')
}

// -------------------------------------

const SearchCompanyFilters = memo(function SearchCompanyFilters(
  props: PropsType,
) {
  const { endpoint, register } = props
  const { query } = useRouter()

  const { ref: hiringRef, ...hiringProps } = register('hiring')
  const { ref: hasTrainingRef, ...hasTrainingProps } = register('hasTraining')
  const { ref: companySizeRef, ...companySizeProps } = register('companySize')
  const { ref: visibleOnPlatformRef, ...visibleOnPlatformProps } =
    register('visibleOnPlatform')

  return (
    <>
      <RadiusFilter {...props} />
      <FilterPopover isFiltering={!isEmptyOrNil(query?.hiring)} label="Hiring">
        <ul>
          <Checkbox
            label="Currently has available positions"
            reference={hiringRef}
            {...hiringProps}
          />
        </ul>
      </FilterPopover>

      <FilterPopover
        isFiltering={!isEmptyOrNil(query?.companySize)}
        label={
          query?.companySize
            ? companySizeMap[query.companySize as CompanySizeEnum]
            : 'Company Size'
        }
      >
        <ChooseCompanySizeRadio
          label="Company Size"
          hideLabel
          name="companySize"
          reference={companySizeRef}
          restProps={companySizeProps}
        />
      </FilterPopover>

      <FilterPopover
        isFiltering={!isEmptyOrNil(query?.hasTraining)}
        label="Training Offered"
      >
        <ul>
          <Checkbox
            label="Has trainings available"
            reference={hasTrainingRef}
            {...hasTrainingProps}
          />
        </ul>
      </FilterPopover>

      {endpoint === 'searchClientsByRecruiters' && (
        <FilterPopover
          isFiltering={!isEmptyOrNil(query?.visibleOnPlatform)}
          label={
            query?.visibleClient === 'true'
              ? 'Visible Companies'
              : query?.visibleClient === 'false'
              ? 'Hidden Companies'
              : 'Company Visibility'
          }
        >
          <ChooseBooleanCheckbox
            hideLabel
            label="Company Visibility"
            reference={visibleOnPlatformRef}
            restProps={visibleOnPlatformProps}
            name="visibleOnPlatform"
            trueLabel="Visible Companies"
            falseLabel="Hidden Companies"
          />
        </FilterPopover>
      )}
    </>
  )
})

// -------------------------------------

const SearchMemberFilters = memo(function SearchMemberFilters(
  props: PropsType,
) {
  const { query } = useRouter()
  const [toggle, setToggle] = useState(true)
  const { endpoint, register, setValue, getValues, change } = props
  const { ref: hiringStatusRef, ...hiringStatusProps } =
    register('hiringStatus')
  const { ref: skillsRef, ...skillsProps } = register('skills')
  const { ref: isAbleToApplyRef, ...isAbleToApplyProps } =
    register('isAbleToApply')
  const { ref: licenseRef, ...licenseProps } = register('possessedLicenseIds')
  const { ref: claimedStatusRef, ...claimedStatusProps } =
    register('termsAccepted')
  const licenseValue = getValues('possessedLicenseIds')
  const skillsValue = getValues('skills')

  const handleValueChange = (name: string, val: string[]) => {
    setValue(name, val)
    setToggle(!toggle)
    change()
  }

  return (
    <>
      <RadiusFilter {...props} />
      <FilterPopover
        isFiltering={!isEmptyOrNil(query?.skills)}
        label="Candidate Skills"
      >
        <ChooseSkillsCombo
          handleValueChange={handleValueChange}
          value={skillsValue}
          label="Skills"
          hideLabel
          reference={skillsRef}
          {...skillsProps}
        />
      </FilterPopover>
      {(endpoint === 'searchCandidatesByClients' ||
        endpoint === 'searchCandidatesByRecruiters') && (
        <>
          <FilterPopover
            isFiltering={!isEmptyOrNil(query?.hiringStatus)}
            label={
              query?.hiringStatus
                ? getMemberHiringStatusLabel(
                    query.hiringStatus as HiringStatusEnum,
                  )
                : 'Hiring Status'
            }
          >
            <ChooseHiringStatuses
              label="Hiring Status"
              reference={hiringStatusRef}
              restProps={hiringStatusProps}
              name="hiringStatus"
            />
          </FilterPopover>
          <FilterPopover
            isFiltering={!isEmptyOrNil(query?.possessedLicenseIds)}
            label="Licenses Held"
          >
            <ChooseLicenseCombo
              handleValueChange={handleValueChange}
              value={licenseValue}
              label="License"
              hideLabel
              reference={licenseRef}
              {...licenseProps}
            />
          </FilterPopover>
        </>
      )}

      {endpoint === 'searchCandidatesByRecruiters' && (
        <>
          <FilterPopover
            isFiltering={!isEmptyOrNil(query?.isAbleToApply)}
            label="Completed Profile"
          >
            <ul>
              <Checkbox
                label="Has Complete Profile"
                reference={isAbleToApplyRef}
                {...isAbleToApplyProps}
              />
            </ul>
          </FilterPopover>

          <FilterPopover
            isFiltering={!isEmptyOrNil(query?.termsAccepted)}
            label={
              query?.termsAccepted === 'true'
                ? 'Claimed'
                : query?.termsAccepted === 'false'
                ? 'Unclaimed'
                : 'Account Status'
            }
          >
            <ChooseBooleanCheckbox
              label={'Account Status'}
              reference={claimedStatusRef}
              restProps={claimedStatusProps}
              name="termsAccepted"
              trueLabel="Claimed"
              falseLabel="Unclaimed"
            />
          </FilterPopover>
        </>
      )}
    </>
  )
})

const getMemberHiringStatusLabel = (status: HiringStatusEnum) => {
  const mapped = {
    [HiringStatusEnum.Available]: 'Actively searching',
    [HiringStatusEnum.Open]: 'Open to opportunities',
    [HiringStatusEnum.Unavailable]: 'Unavailable',
  }
  return status
    .split(',')
    .map((stat) => mapped[stat as HiringStatusEnum])
    .join(', ')
}

// -------------------------------------

function SearchFilters(props: PropsType) {
  const {
    change,
    className,
    endpoint,
    register,
    getValues,
    setValue, // not in use but we don't want to spread it on the Popover.Group
    ...rest
  } = props

  const { authenticating } = useViewer()

  if (authenticating || endpoint === 'searchCredentials') {
    return (
      <div className="mood-white min-h-16 shadow-md">
        <div className="contain-xc flex items-center gap-2 ">
          {authenticating && <Busy />}
        </div>
      </div>
    )
  }

  return (
    <div className="mood-white flex min-h-16 items-center border-t shadow-md">
      <Popover.Group
        className={clsx(
          'contain-xc flex items-center gap-2',
          'overflow-x-auto lg:flex-wrap lg:overflow-x-visible lg:py-2',
          '[&_>_*]:min-w-fit',
          className,
        )}
        {...rest}
      >
        {(endpoint === 'searchJobPostings' ||
          endpoint === 'searchJobPostingsByRecruiters') && (
          <SearchJobFilters {...props} />
        )}
        {(endpoint === 'searchClients' ||
          endpoint === 'searchClientsByRecruiters') && (
          <SearchCompanyFilters {...props} />
        )}
        {(endpoint === 'searchCandidates' ||
          endpoint === 'searchCandidatesByClients' ||
          endpoint === 'searchCandidatesByRecruiters') && (
          <SearchMemberFilters {...props} />
        )}
      </Popover.Group>
    </div>
  )
}

/**
 * Various filters based on the type of search and user permissions.
 */
export default memo(SearchFilters)
