import omitEmptyOrNil from '@vangst/lib/omitEmptyOrNil'
import { parse, stringify } from '@vangst/lib/queryString'
import toBoolean from '@vangst/lib/toBoolean'
import addDays from 'date-fns/addDays'
import formatISO from 'date-fns/formatISO'
import subDays from 'date-fns/subDays'
import { useRouter } from 'next/router'
import { ParsedQuery } from 'query-string'

const dateQueryStringHelper = (
  dateMeasure: 'startDate' | 'endDate',
  val: any,
) => {
  let days = parseInt(val)
  if (isNaN(days)) {
    return undefined
  }

  // prevents users from searching > 30 days back by modifying query params
  if (days > 30) {
    days = 30
  }
  const today = new Date()
  const date =
    dateMeasure === 'startDate' ? subDays(today, days) : addDays(today, days)

  return { [dateMeasure]: formatISO(date, { representation: 'date' }) }
}

const parseDates = (params: ParsedQuery<string | number | boolean>) => {
  return {
    betweenTimes: dateQueryStringHelper('startDate', params?.betweenTimes),
    jobStartDateBetween: dateQueryStringHelper(
      'endDate',
      params?.jobStartDateBetween,
    ),
  }
}

const parser = (s: string | any) =>
  parse(s, { arrayFormat: 'comma', parseBooleans: true, parseNumbers: true })

/**
 * Expose the parsing of params based on some common search fields.
 */
export function parseParams(params: string) {
  const queryParams = parser(params)
  if (queryParams.term) {
    queryParams.term = String(queryParams.term)
  }
  const nextMatch = queryParams.matchLocation
    ? {
        matchLocation: {
          ...parser(queryParams.matchLocation),
          country: 'united_states',
        },
      }
    : undefined

  const nextRadius = queryParams.radiusLocation
    ? { radiusLocation: parser(queryParams.radiusLocation) }
    : undefined

  const nextDates = parseDates(queryParams)
  const nextParams = omitEmptyOrNil({
    ...queryParams,
    ...nextMatch,
    ...nextRadius,
    ...nextDates,
  })
  return nextParams
}

/**
 * Expose getting/setting + parsing/stringifying query params for use with
 * Vangst search.
 */
export default function useQueryParams() {
  const { push } = useRouter()

  const getQueryParams = () => {
    const search = globalThis.location?.search
    return parseParams(search)
  }
  const formatBooleanMultiSelect = (val: string[] | string) => {
    if (Array.isArray(val) && val.includes('true') && val.includes('false')) {
      return null
    }
    return toBoolean(val)
  }

  const setQueryParams = (newQueryParams: any) => {
    const {
      betweenTimes,
      hiring,
      isPublished,
      isRemote,
      jobStartDateBetween,
      orderBy,
      radiusLocation,
      termsAccepted,
      visibleClient,
      visibleOnPlatform,
    } = newQueryParams

    const nextParams = omitEmptyOrNil({
      ...newQueryParams,
      betweenTimes: betweenTimes ? betweenTimes : undefined,
      hiring: hiring ? toBoolean(hiring) : undefined,
      isPublished: isPublished
        ? formatBooleanMultiSelect(isPublished)
        : undefined,
      isRemote: isRemote ? formatBooleanMultiSelect(isRemote) : undefined,
      jobStartDateBetween: jobStartDateBetween
        ? jobStartDateBetween
        : undefined,
      matchLocation: undefined,
      orderBy: orderBy ? orderBy : undefined,
      radiusLocation: radiusLocation?.radius
        ? stringify(radiusLocation)
        : undefined,
      termsAccepted: termsAccepted
        ? formatBooleanMultiSelect(termsAccepted)
        : undefined,
      visibleClient: visibleClient
        ? formatBooleanMultiSelect(visibleClient)
        : undefined,
      visibleOnPlatform: visibleOnPlatform
        ? formatBooleanMultiSelect(visibleOnPlatform)
        : undefined,
    })

    const search = stringify(nextParams, { arrayFormat: 'comma' })
    push({ pathname: globalThis.location.pathname, query: search }, undefined, {
      scroll: false,
    })
  }

  return { getQueryParams, setQueryParams }
}
