import clsx from '@vangst/lib/clsx'
import { getPlace } from '@vangst/services/google/maps.client'
import { memo, useState } from 'react'
import { MdLocationPin } from 'react-icons/md'
import PlacesAutocomplete from 'react-places-autocomplete'

type PropsType = React.InputHTMLAttributes<HTMLInputElement> & {
  readonly defaultAddress?: string
  readonly placeError?: string | null
  readonly setPlaceError?: (value: string | null) => void
  readonly setPlace: (
    value: string | { lat: number; lng: number } | null,
  ) => Promise<void>
}

function SearchPlace(props: PropsType) {
  const {
    id = 'searchplace',
    defaultAddress = '',
    placeError,
    setPlace,
    setPlaceError,
  } = props
  const inputId = id + '-input'
  const [address, setAddress] = useState(defaultAddress)

  const searchOptions = {
    componentRestrictions: { country: ['us'] },
    types: ['(cities)'],
  }

  const handleChange = (address: string) => {
    setAddress(address)
    if (address === '') setPlace(null)
    if (placeError) setPlaceError?.(null)
  }

  const handleError = (status: string, clearSuggestions: () => void) => {
    const message = status.includes('ZERO_RESULTS')
      ? 'No results, try to find the best available address suggestion'
      : status
    setPlaceError?.(message)
    clearSuggestions()
  }

  const handleSelect = async (selectedAddress: string) => {
    setAddress(selectedAddress)
    setPlace(selectedAddress)
  }

  const handleGeoLocation = () => {
    async function success(position: GeolocationPosition) {
      const coords = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      }
      setPlace(coords)
      const addy = await getPlace(coords)
      addy?.address && setAddress(addy.address)
    }

    function error() {
      setPlaceError?.('Unable to retrieve your location')
    }

    !navigator.geolocation
      ? setPlaceError?.('Geolocation is not supported by your browser')
      : navigator.geolocation.getCurrentPosition(success, error)
  }

  return (
    <label
      className="mood-white relative flex h-11 w-full items-center gap-2 rounded-md border border-grey-light"
      htmlFor={inputId}
    >
      <span className="sr-only">Search Location</span>
      <PlacesAutocomplete
        googleCallbackName="Function.prototype"
        highlightFirstSuggestion
        onChange={handleChange}
        onSelect={handleSelect}
        onError={handleError}
        searchOptions={searchOptions}
        value={address}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <>
            <input
              id={inputId}
              className="peer/searchinput w-full truncate border-0 p-0 transition-colors placeholder:italic focus:ring-0"
              {...getInputProps({ placeholder: 'Search for a location' })}
              disabled={false}
            />
            <ul className="mood-white absolute inset-x-0 top-11 z-40 rounded-b text-left text-sm shadow-lg">
              {placeError && (
                <li className="w-full border-t border-grey-light p-2 text-red first:border-t-0">
                  {placeError}
                </li>
              )}
              {suggestions.map((suggestion, i) => (
                <li
                  className={clsx(
                    'w-full cursor-pointer border-t border-grey-light p-2 first:border-t-0 hocus:bg-blue',
                    suggestion.active && 'bg-blue font-600',
                  )}
                  {...getSuggestionItemProps(suggestion)}
                  key={`suggestion-option-${i}`}
                >
                  {suggestion.description}
                </li>
              ))}
            </ul>

            <span
              className="order-first border-r border-grey-light px-2 font-700 text-orange peer-focus/searchinput:border-current"
              role="presentation"
            >
              City
            </span>
            <button
              className="flex h-full min-w-11 items-center justify-center rounded-r-md bg-orange text-white"
              onClick={handleGeoLocation}
              title="Use current location"
              type="button"
            >
              <span className="sr-only">Use current location</span>
              <MdLocationPin
                className={clsx(loading && 'scale-90 transition ease-bounce')}
                size="1.75em"
              />
            </button>
          </>
        )}
      </PlacesAutocomplete>
    </label>
  )
}

/**
 * Captures `location` for the search query.
 *
 * @example
 * const { register } = useRouterControl()
 * <SearchLocation register={register} />
 */
export default memo(SearchPlace)
