import { SkillFragment } from '@vangst/services/oogst/types'
import useSkills from '@vangst/services/oogst/wtf/useSkills'
import React, { useMemo } from 'react'
import Busy from '../../../components/feedback/Busy'
import ComboBoxMulti from '../../../components/forms/ComboBoxMulti'
import { SelectType } from '../../../components/forms/Select'

type PropsType = SelectType

function ChooseSkillsCombo(
  props: PropsType & {
    fieldLabel?: string
    handleValueChange: (name: string, value: string[]) => void
  },
) {
  const {
    error,
    hint,
    hideLabel,
    label,
    reference,
    name,
    handleValueChange,
    value,
    ...rest
  } = props

  const { data: sd, isLoading: isSkillsLoading } = useSkills()
  const skills = sd?.skills

  const id = props?.id ?? name

  const listOfSkills = useMemo(() => {
    return skills?.map((skill: SkillFragment) => ({
      label: skill.name,
      value: skill.name,
    }))
  }, [skills])

  const skillOptions = [
    { label: 'Select Skill', value: '' },
    ...(listOfSkills ?? []),
  ]

  if (isSkillsLoading) return <Busy />
  return (
    <ComboBoxMulti
      id={id}
      handleValueChange={handleValueChange}
      value={value as string[]}
      error={error}
      hideLabel={hideLabel}
      label={label}
      name={name}
      options={skillOptions}
      {...rest}
    />
  )
}

export default React.memo(ChooseSkillsCombo)
