import { HiringStatusEnum } from '@vangst/services/oogst/types'
import React from 'react'
import Checkbox from '../../../components/forms/Checkbox'
import ControlGroup from '../../../components/forms/ControlGroup'

type PropsType = React.InputHTMLAttributes<
  HTMLInputElement | HTMLLabelElement
> & {
  readonly defaultValue?: HiringStatusEnum[]
  readonly error?: string
  readonly hideLabel?: boolean
  readonly hint?: string | null
  readonly label: string
  readonly layout?: 'col' | 'row'
  readonly name: string
  readonly reference?: React.Ref<HTMLInputElement>
  readonly restProps?: object
}

function ChooseHiringStatuses(props: PropsType) {
  const {
    error,
    hint,
    hideLabel = false,
    label,
    layout = 'col',
    name,
    reference,
    restProps,
  } = props

  return (
    <ControlGroup
      disabled={props.disabled}
      error={error}
      hideLabel={hideLabel}
      hint={hint}
      label={label}
      layout={layout}
      name={name}
      type="checkbox"
    >
      <Checkbox
        id={`${name}.${HiringStatusEnum.Available}`}
        name={name}
        label="Actively searching"
        reference={reference}
        value={HiringStatusEnum.Available}
        {...restProps}
      />
      <Checkbox
        id={`${name}.${HiringStatusEnum.Open}`}
        name={name}
        label="Open to opportunities"
        reference={reference}
        value={HiringStatusEnum.Open}
        {...restProps}
      />
    </ControlGroup>
  )
}

export default React.memo(ChooseHiringStatuses)
