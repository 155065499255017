import React from 'react'
import Checkbox from '../../../components/forms/Checkbox'
import ControlGroup from '../../../components/forms/ControlGroup'

type PropsType = React.InputHTMLAttributes<
  HTMLInputElement | HTMLLabelElement
> & {
  readonly error?: string
  readonly falseLabel: string
  readonly hideLabel?: boolean
  readonly hint?: string | null
  readonly label: string
  readonly layout?: 'col' | 'row'
  readonly name: string
  readonly reference?: React.Ref<HTMLInputElement>
  readonly restProps?: object
  readonly trueLabel: string
}

function ChooseBooleanCheckbox(props: PropsType) {
  const {
    error,
    falseLabel,
    hint,
    hideLabel = false,
    label,
    layout = 'col',
    name,
    reference,
    restProps,
    trueLabel,
  } = props

  return (
    <ControlGroup
      disabled={props.disabled}
      error={error}
      hideLabel={hideLabel}
      hint={hint}
      label={label}
      layout={layout}
      name={name}
      type="checkbox"
    >
      <Checkbox
        id={trueLabel}
        name={name}
        label={trueLabel}
        reference={reference}
        value={'true'}
        {...restProps}
      />
      <Checkbox
        id={falseLabel}
        name={name}
        label={falseLabel}
        reference={reference}
        value={'false'}
        {...restProps}
      />
    </ControlGroup>
  )
}

export default React.memo(ChooseBooleanCheckbox)
