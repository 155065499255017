import ControlGroup from '../../../components/forms/ControlGroup'
import Radio from '../../../components/forms/Radio'

type PropsType = React.InputHTMLAttributes<
  HTMLInputElement | HTMLLabelElement
> & {
  readonly error?: string
  readonly hideLabel?: boolean
  readonly hint?: string | null
  readonly label: string
  readonly layout?: 'col' | 'row'
  readonly name: string
  readonly reference?: React.Ref<HTMLInputElement>
  readonly restProps?: object
}

function ChooseRadiusRadio(props: PropsType) {
  const { error, hint, label, layout, name, reference, restProps } = props
  return (
    <ControlGroup
      error={error}
      hint={hint}
      label={label}
      layout={layout}
      name={name}
      type="radio"
    >
      <Radio
        label="10 mi"
        name={name}
        reference={reference}
        value={'10'}
        {...restProps}
      />
      <Radio
        label="20 mi"
        name={name}
        reference={reference}
        value={'20'}
        {...restProps}
      />
      <Radio
        label="30 mi"
        name={name}
        reference={reference}
        value={'30'}
        {...restProps}
      />
      <Radio
        label="40 mi"
        name={name}
        reference={reference}
        value={'40'}
        {...restProps}
      />
      <Radio
        label="50 mi"
        name={name}
        reference={reference}
        value={'50'}
        {...restProps}
      />
    </ControlGroup>
  )
}

export default ChooseRadiusRadio
