import { AtsRecordTypeEnum } from '@vangst/services/oogst/types'
import useViewer from '@vangst/services/oogst/viewer/useViewer'
import { memo } from 'react'
import { UseFormRegister, UseFormWatch } from 'react-hook-form'
import Checkbox from '../../../components/forms/Checkbox'
import ControlGroup from '../../../components/forms/ControlGroup'

type PropsType = React.InputHTMLAttributes<
  HTMLInputElement | HTMLLabelElement
> & {
  readonly defaultValue?: AtsRecordTypeEnum[]
  readonly error?: string
  readonly hideLabel?: boolean
  readonly hint?: string | null
  readonly layout?: 'col' | 'row'
  readonly name: string
  readonly reference?: any
  readonly register: UseFormRegister<any>
  readonly watch?: UseFormWatch<any>
}

function ChooseAtsRecordType(props: PropsType) {
  const { viewer } = useViewer()
  const {
    error,
    hideLabel = false,
    hint,
    layout = 'col',
    name,
    reference,
    ...rest
  } = props

  return (
    <ControlGroup
      disabled={props.disabled}
      error={error}
      hideLabel={hideLabel}
      hint={hint}
      label="Type Of Job"
      layout={layout}
      name={name}
      type="checkbox"
    >
      <Checkbox
        id={`${name}.${AtsRecordTypeEnum.BoutiqueGigs}`}
        label="Gigs"
        name={name}
        reference={reference}
        value={AtsRecordTypeEnum.BoutiqueGigs}
        {...rest}
      />
      {viewer?.permissions?.searchJobPostingsByRecruiter && (
        <Checkbox
          id={`${name}.${AtsRecordTypeEnum.BoutiqueDh}`}
          label="Direct Hire"
          name={name}
          reference={reference}
          value={AtsRecordTypeEnum.BoutiqueDh}
          {...rest}
        />
      )}
      {viewer?.permissions?.searchJobPostingsByRecruiter && (
        <Checkbox
          id={`${name}.${AtsRecordTypeEnum.PlatformFree}`}
          label="Self Service"
          name={name}
          reference={reference}
          value={AtsRecordTypeEnum.PlatformFree}
          {...rest}
        />
      )}
    </ControlGroup>
  )
}

export default memo(ChooseAtsRecordType)
