import React from 'react'
import ControlGroup from '../../../components/forms/ControlGroup'
import Radio from '../../../components/forms/Radio'

type PropsType = React.InputHTMLAttributes<
  HTMLInputElement | HTMLLabelElement
> & {
  readonly error?: string
  readonly hideLabel?: boolean
  readonly hint?: string | null
  readonly label: string
  readonly layout?: 'col' | 'row'
  readonly name: string
  readonly reference?: React.Ref<HTMLInputElement>
  readonly restProps?: object
}

function ChooseJobDateRangeRadio(props: PropsType) {
  const {
    error,
    hint,
    hideLabel = false,
    label,
    layout = 'col',
    name,
    reference,
    restProps,
  } = props

  return (
    <ControlGroup
      disabled={props.disabled}
      error={error}
      hideLabel={hideLabel}
      hint={hint}
      label={label}
      layout={layout}
      name={name}
      type="checkbox"
    >
      <Radio
        id={'all'}
        name={name}
        label="All Jobs"
        reference={reference}
        value={''}
        {...restProps}
      />
      <Radio
        id={'seven'}
        name={name}
        label="Last 7 Days"
        reference={reference}
        value={'7'}
        {...restProps}
      />
      <Radio
        id={'fouteen'}
        name={name}
        label="Last 14 Days"
        reference={reference}
        value={'14'}
        {...restProps}
      />
      <Radio
        id={'thiry'}
        name={name}
        label="Last 30 Days"
        reference={reference}
        value={'30'}
        {...restProps}
      />
    </ControlGroup>
  )
}

export default React.memo(ChooseJobDateRangeRadio)
