import debounce from '@vangst/lib/debounce'
import omitEmptyOrNil from '@vangst/lib/omitEmptyOrNil'
import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import useQueryParams from './useQueryParams'

const allowedParams = Object.freeze([
  'after',
  'atsRecordType',
  'atsRecordTypes',
  'before',
  'betweenTimes',
  'city',
  'companySize',
  'country',
  'employmentTypes',
  'first',
  'hasALicense',
  'hasClientInterest',
  'hasResume',
  'hasTraining',
  'hasWorkExperience',
  'hiring',
  'hiringStatus',
  'isAbleToApply',
  'isNeedEmploymentSponsor',
  'isPublished',
  'isAccountExec',
  'isRecruiter',
  'isRemote',
  'isUsWorkAuthorized',
  'jobStartDateBetween',
  'last',
  'matchLocation',
  'orderBy',
  'possessedLicenseIds',
  'radius',
  'radiusLocation',
  'requiredLicenseIds',
  'searchTerm',
  'skills',
  'state',
  'term',
  'termsAccepted',
  'visibleClient',
  'visibleOnPlatform',
])

/**
 * Use the router to capture and set parameters via a form object for
 * controlling search queries.
 */
export default function useRouterControl(extras?: any) {
  const { getQueryParams, setQueryParams } = useQueryParams()

  // This should probably get a test and move to useQueryParams?
  const params = useMemo(() => {
    const queryParams = getQueryParams()
    const queryParamKeys = Object.keys(queryParams)
    return queryParamKeys.reduce((acc, cur) => {
      if (cur === 'skills' && typeof queryParams[cur] === 'string') {
        acc[cur] = [queryParams[cur] as string]
      } else if (
        (cur === 'possessedLicenseIds' ||
          cur === 'requiredLicenseIds' ||
          cur === 'isRemote' ||
          cur === 'employmentTypes') &&
        typeof queryParams[cur] === 'string'
      ) {
        acc[cur] = [queryParams[cur] as string]
      } else if (typeof queryParams[cur] === 'boolean') {
        acc[cur] = queryParams[cur].toString()
      } else if (cur === 'radiusLocation') {
        queryParams[cur].radius = queryParams[cur].radius.toString()
        acc[cur] = queryParams[cur]
      } else if (cur === 'betweenTimes') {
        // this is to convert a timestamp to a stringified integer for the radio buttons
        const val = new Date(queryParams[cur].startDate)
        // @ts-expect-error - it complains about subtracting dates
        const duration = new Date() - val
        const days = duration / (1000 * 3600 * 24)
        const result = Math.floor(days)
        acc[cur] = result.toString()
      } else if (allowedParams.includes(cur)) {
        acc[cur] = queryParams[cur]
      }
      return acc
    }, {} as any)
  }, [getQueryParams])

  const { control, handleSubmit, register, reset, watch, setValue, getValues } =
    useForm({
      defaultValues: params,
    })

  const setParams = useCallback(
    (input: any) =>
      setQueryParams(omitEmptyOrNil({ ...input, ...extras }, false, true)),
    [setQueryParams, extras],
  )

  const submit = useMemo(
    () => handleSubmit(setParams),
    [handleSubmit, setParams],
  )

  const change = () => {
    submit()
  }
  const debounced = debounce(() => submit(), { wait: 250 })

  return {
    change,
    control,
    debounced,
    register,
    watch,
    setValue,
    getValues,
    reset,
    submit,
  }
}
