type GeoResult = google.maps.GeocoderResult

export function findLatLng(result: GeoResult) {
  const { location } = result.geometry ?? {}
  const lat =
    typeof location?.lat === 'function' ? location?.lat() : location?.lat
  const lng =
    typeof location?.lng === 'function' ? location?.lng() : location?.lng
  return { lat, lng }
}

export function findAddressComponent(result: GeoResult, type: string) {
  return result.address_components?.find((component) =>
    component.types.includes(type),
  )
}

export function findCity(result: GeoResult) {
  return findAddressComponent(result, 'locality')
}

export function findState(result: GeoResult) {
  return findAddressComponent(result, 'administrative_area_level_1')
}

export function formatAddress(result: GeoResult) {
  const city = findCity(result)
  const state = findState(result)
  return city && state ? `${city.long_name}, ${state.short_name}, USA` : ''
}
