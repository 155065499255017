import { LicenseFragment } from '@vangst/services/oogst/types'
import useLicenses from '@vangst/services/oogst/wtf/useLicenses'
import React, { useMemo } from 'react'
import Busy from '../../../components/feedback/Busy'
import ComboBoxMulti from '../../../components/forms/ComboBoxMulti'
import { SelectType } from '../../../components/forms/Select'

type PropsType = SelectType

function ChooseLicenseCombo(
  props: PropsType & {
    fieldLabel?: string
    handleValueChange: (name: string, value: string[]) => void
  },
) {
  const {
    error,
    hideLabel,
    hint,
    label,
    name,
    reference,
    value,
    handleValueChange,
    ...rest
  } = props

  const { data: ld, isLoading: isLicensesLoading } = useLicenses()
  const licenses = ld?.licenses

  const id = props?.id ?? name

  const listOfLicenses = useMemo(() => {
    return licenses?.map((license: LicenseFragment) => ({
      label: `${license.state} - ${license.name}`,
      value: license.id ?? '',
    }))
  }, [licenses])

  const licenseOptions = [...(listOfLicenses ?? [])]
  if (isLicensesLoading) return <Busy />
  return (
    <ComboBoxMulti
      id={id}
      handleValueChange={handleValueChange}
      value={value as string[]}
      error={error}
      hideLabel={hideLabel}
      label={label}
      name={name}
      options={licenseOptions}
      {...rest}
    />
  )
}

export default React.memo(ChooseLicenseCombo)
